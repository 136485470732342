import React, { useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../Php';
import { useNavigate } from 'react-router-dom';

export const CreateAC = () => {
  const [username, setUsername] = useState('');
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
      e.preventDefault();
      
      if (password !== confirmPassword) {
          alert('Passwords do not match.');
          return;
      }

      
      const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      if (!passwordRegex.test(password)) {
          alert('Password must contain at least one uppercase letter and one symbol (!@#$%^&*), and be at least 8 characters long.');
          return;
      }

      try {
          
          const response = await axios.post(`${API_PATH}/interview-project/create_ac.php`, {
              username,
              emailId,
              password,
          });

          if (response.status === 200) {
              alert('Account created successfully.');
              setUsername('');
              setEmailId('');
              setPassword('');
              setConfirmPassword('');
              navigate('/login')
          } else {
              alert('Failed to create account.');
          }
      } catch (error) {
          console.error('Error creating account:', error);
          alert('An error occurred. Please try again.');
      }
  };
  return (
    <div>
        <h1>Sign Up Form</h1>
        <form onSubmit={handleRegister}>
           <label htmlFor="">User Name</label>
           <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} /><br />
           <label htmlFor="">Email ID</label>
           <input type="email" value={emailId} onChange={(e) => setEmailId(e.target.value)} /><br />
           <label htmlFor="">Password</label>
           <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /><br />
           <label htmlFor="">Confirm Password</label>
           <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} /><br />
           <input type="submit"/>
        </form>
    </div>
  );
}

