import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_PATH } from "../Php";
import axios from "axios";
import bcrypt from "bcryptjs-react";

export const Login = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${API_PATH}/interview-project/login.php`);
                setUserData(response.data);
                console.log(response);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('An error occurred while fetching user data.');
            }
        };

        fetchUserData();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();

        
        if (username.trim() === '' || password.trim() === '') {
            setError('Please fill in both username and password fields.');
            return;
        }

        
        setError('');

        
        const foundUser = userData.find((user) => user.username === username);
        if (foundUser) {
            
            const passwordMatch = await bcrypt.compare(password, foundUser.password);

            if (passwordMatch) {
                
                navigate('/home');
                setUsername('');
                setPassword('');
                sessionStorage.setItem('username', username);
            } else {
                setError('Invalid username or password.');
            }
        } else {
            setError('Invalid username or password.');
        }
    };
    return(
        <div>
            <h1>Log in</h1>
            <form onSubmit={handleLogin}>
                <label htmlFor="">User Name</label>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}/><br />
                <label htmlFor="">Password</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                {error && <div>{error}</div>}
                <button type='submit'>Log in</button><br />
            </form>
        </div>
    )
}