import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CreateAC } from './Users/Create-ac';
import { Login } from './Users/Login';
import { Home } from './Users/Home';

function App() {
  
  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<CreateAC/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/home' element={<Home/>}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;